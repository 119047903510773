<template>
	<v-sheet class="asset" id="asset" style="height: calc(100vh - 88px)">
		<CreateDialog :dialog="createDialog" v-on:close="createDialog = false">
			<template v-slot:title>
				<span>{{ pageTitle() }} </span>
			</template>
			<template v-slot:body>
				<template v-if="!barcodeLoading">
					<v-row>
						<v-col md="12">
							<v-stepper flat tile class="item-group-stepper" v-model="stepper">
								<v-stepper-header>
									<v-stepper-step class="py-2 btx-label" :complete="stepper > 1" step="1">
										Overview
									</v-stepper-step>

									<v-divider></v-divider>

									<template v-if="asset.has_checkOut == true && asset.is_allocate">
										<v-stepper-step class="py-2 btx-label" :complete="stepper > 2" step="2">
											Allocate Details
										</v-stepper-step>
										<v-divider></v-divider>
									</template>

									<template v-if="asset.check_warranty == true">
										<template v-if="asset.has_checkOut == false || asset.is_allocate == false">
											<v-stepper-step class="py-2 btx-label" :complete="stepper > 2" step="2">
												Warranty Details
											</v-stepper-step>
										</template>
										<template v-else>
											<v-stepper-step class="py-2 btx-label" :complete="stepper > 3" step="3">
												Warranty Details
											</v-stepper-step>
										</template>
										<v-divider></v-divider>
									</template>

									<template
										v-if="
											asset.check_warranty == false &&
											(asset.has_checkOut == false || asset.is_allocate == false)
										"
									>
										<v-stepper-step class="py-2 btx-label" :complete="stepper > 2" step="2">
											Maintenance Details
										</v-stepper-step>
									</template>
									<template
										v-else-if="
											asset.check_warranty == true && asset.has_checkOut == true && asset.is_allocate == true
										"
									>
										<v-stepper-step class="py-2 btx-label" :complete="stepper > 4" step="4">
											Maintenance Details
										</v-stepper-step>
									</template>

									<template v-else>
										<v-stepper-step class="py-2 btx-label" :complete="stepper > 3" step="3">
											Maintenance Details
										</v-stepper-step>
									</template>
								</v-stepper-header>
								<v-stepper-items>
									<v-form
										ref="assetForm"
										v-model.trim="formValid"
										lazy-validation
										v-on:submit.stop.prevent="updateOrCreate"
									>
										<v-stepper-content class="pt-0" step="1">
											<perfect-scrollbar
												:options="{ suppressScrollX: true }"
												class="scroll custom-box-top-inner-shadow"
												style="max-height: calc(100vh - 185px); position: relative"
											>
												<v-row>
													<v-col md="8">
														<perfect-scrollbar
															:options="{ suppressScrollX: true }"
															class="scroll custom-box-top-inner-shadow"
															style="max-height: calc(100vh - 160px); position: relative"
														>
															<v-row>
																<v-col md="12" class="py-0">
																	<p class="middle-header-background mb-4 px-4">
																		Core Information<br />
																		<span>
																			<v-icon small>mdi-progress-question</v-icon> Specify the primary description for
																			your asset
																		</span>
																	</p>
																</v-col>
															</v-row>
															<v-row class="px-4">
																<v-col md="3" class="my-auto py-0">
																	<label for="name" class="btx-label mt-2 required">Name</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<TextInput
																		hide-details
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="name"
																		:rules="[vrules.required(asset.name, 'Name')]"
																		:class="{
																			required: !asset.name,
																		}"
																		placeholder="Name"
																		v-model="asset.name"
																	></TextInput>
																</v-col>
																<v-col md="3" class="my-auto py-0">
																	<label for="name" class="btx-label mt-2">Barcode</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<TextInput
																		hide-details
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="pico-barcode"
																		placeholder="Barcode"
																		v-model="asset.pico_barcode"
																	>
																	</TextInput>
																</v-col>
																<v-col md="3" class="my-auto py-0">
																	<label for="name" class="btx-label mt-2">Password</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<TextInput
																		hide-details
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="password"
																		placeholder="Password"
																		v-model="asset.password"
																	></TextInput>
																</v-col>
																<template v-if="false">
																	<v-col md="3" class="my-auto py-0">
																		<label for="model" class="btx-label mt-2 required">Model Number</label>
																	</v-col>
																	<v-col md="9" class="py-0">
																		<TextInput
																			hide-details
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			id="model"
																			placeholder="Model Number"
																			v-model="asset.model"
																			:rules="[vrules.required(asset.model, 'Model')]"
																			:class="{
																				required: !asset.model,
																			}"
																		></TextInput>
																	</v-col>
																</template>
																<v-col md="3" class="my-auto py-0">
																	<label for="group" class="btx-label mt-2 required">Category</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<AutoCompleteInput
																		hide-details
																		:items="categoryList"
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="group"
																		:rules="[vrules.required(asset.group, 'Group')]"
																		:class="{
																			required: !asset.group,
																		}"
																		v-on:change="getSubGroup($event)"
																		placeholder="Category"
																		v-model="asset.group"
																		append-outer-icon="mdi-cog"
																		v-on:click:append-outer="manageCategoryDialog = true"
																	>
																	</AutoCompleteInput>
																</v-col>
																<template v-if="false && subGroupList.length">
																	<v-col md="3" class="my-auto py-0">
																		<label for="sub-group" class="btx-label mt-2">Sub Group</label>
																	</v-col>
																	<v-col md="9" class="py-0">
																		<AutoCompleteInput
																			hide-details
																			:items="subGroupList"
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			id="sub-group"
																			placeholder="Sub Group"
																			v-model="asset.sub_group"
																		></AutoCompleteInput>
																	</v-col>
																</template>
																<template v-if="false">
																	<v-col md="3" class="my-auto py-0">
																		<label for="id-number" class="btx-label mt-2"
																			>Identification Number
																			<TooltipQuestion>
																				<template v-slot:text
																					>Your unique identifier for this<br />Asset. It can also be printed<br />and
																					scanned as a barcode.</template
																				>
																			</TooltipQuestion>
																		</label>
																	</v-col>
																	<v-col md="9" class="py-0">
																		<TextInput
																			hide-details
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			id="id-number"
																			placeholder="Identification Number"
																			v-model="asset.id_number"
																		></TextInput>
																	</v-col>
																</template>
																<v-col md="3" class="py-0">
																	<label for="description" class="btx-label mt-2v">Description</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<TextAreaInput
																		hide-details
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="description"
																		placeholder="Description"
																		v-model="asset.description"
																	></TextAreaInput>
																</v-col>
																<v-col md="3" class="my-auto py-0">
																	<label for="contact" class="btx-label mt-2">Contact for Item</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<PhoneInput
																		hide-details
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="contact"
																		placeholder="Contact for Item"
																		v-model="asset.contact"
																	>
																	</PhoneInput>
																</v-col>
																<v-col md="3" class="py-0">
																	<label :for="`return-on-${uniqFieldId}`" class="btx-label mt-3">Fault</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<RadioInput
																		row
																		hide-details
																		v-model="asset.is_fault"
																		:disabled="pageLoading"
																		:id="`service-performed-by-${uniqFieldId}`"
																		:items="faultIteam"
																	></RadioInput>
																</v-col>
																<v-col md="3" class="my-auto py-0">
																	<label for="tags" class="btx-label mt-4">Tags</label>
																</v-col>
																<v-col md="9" class="py-0 mt-3">
																	<v-combobox
																		id="tags"
																		hide-details
																		v-model="asset.tags"
																		class="combobox"
																		:filter="filterTag"
																		:hide-no-data="!search"
																		outlined
																		:items="items"
																		:search-input.sync="search"
																		item-text="tag"
																		item-value="id"
																		hide-selected
																		multiple
																		small-chips
																		dense
																		placeholder="Search Tag"
																	>
																		<template v-slot:no-data>
																			<v-chip label class="my-2 mx-2" small>
																				{{ search }}
																			</v-chip>
																		</template>
																		<template v-slot:selection="{ attrs, item, parent, selected }">
																			<v-chip
																				class="my-1"
																				v-if="item === Object(item)"
																				v-bind="attrs"
																				:input-value="selected"
																				label
																				small
																			>
																				<span class="pr-2">
																					{{ item.tag }}
																				</span>
																				<v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
																			</v-chip>
																		</template>
																		<v-list-item class="my-2 white" v-slot:item="{ item }">
																			<v-list-item-content>
																				<v-list-item-title>
																					<v-chip class="mb-1" label small>
																						{{ item.tag }}
																					</v-chip>
																				</v-list-item-title>
																			</v-list-item-content>
																		</v-list-item>
																	</v-combobox>
																</v-col>
																<v-col md="3" class="my-auto py-0">
																	<label for="purchased-on" class="btx-label mt-2">End Life</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<DatePicker
																		hide-details
																		clearable
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="purchased-on"
																		placeholder="End Life"
																		v-model="asset.end_life"
																		:min-date="currentDate"
																	></DatePicker>
																</v-col>

																<v-col md="3" class="my-auto py-0">
																	<label for="contact" class="btx-label mt-2">Return After End Life</label>
																</v-col>
																<v-col md="9">
																	<v-checkbox
																		dense
																		v-model="asset.is_return"
																		color="blue"
																		hide-details
																		class="mt-0 mb-0"
																	></v-checkbox>
																</v-col>

																<v-col md="3" class="my-auto py-0">
																	<label for="contact" class="btx-label mt-2">Warranty</label>
																</v-col>
																<v-col md="9">
																	<v-checkbox
																		dense
																		v-model="asset.check_warranty"
																		color="blue"
																		hide-details
																		class="mt-0 mb-0"
																	></v-checkbox>
																</v-col>
																<v-col md="3" class="my-auto py-0">
																	<label for="contact" class="btx-label mt-2"></label>
																</v-col>
																<v-col md="9" class="d-flex">
																	<v-checkbox
																		dense
																		v-model="asset.is_allocate"
																		color="blue"
																		label="Internal Allocation"
																		hide-details
																		class="mt-0 mb-0"
																	></v-checkbox>
																	<v-checkbox
																		dense
																		v-model="asset.is_sold"
																		v-if="asset_setting.assetsold"
																		color="blue"
																		label="For Sale"
																		hide-details
																		class="mt-0 mb-0 ml-2"
																	></v-checkbox>
																	<v-checkbox
																		dense
																		v-if="asset_setting.assetrental"
																		v-model="asset.is_rental"
																		color="blue"
																		label="For Rental/Lease"
																		hide-details
																		class="mt-0 mb-0 ml-2"
																	></v-checkbox>
																</v-col>
																<template v-if="uuid == null && asset.is_allocate">
																	<v-col md="3" class="my-auto py-0">
																		<label for="contact" class="btx-label mt-2">Allocate Now</label>
																	</v-col>
																	<v-col md="9">
																		<v-checkbox
																			dense
																			v-model="asset.has_checkOut"
																			color="blue"
																			hide-details
																			class="mt-0 mb-0"
																		></v-checkbox>
																	</v-col>
																</template>
															</v-row>
															<template v-if="asset.is_sold && asset_setting.assetsold">
																<v-row>
																	<v-col md="12" class="py-0">
																		<p class="middle-header-background my-4 mt-7 px-4">
																			Sales Information<br />
																			<span>
																				<v-icon small>mdi-progress-question</v-icon> Specify the sales details for your
																				asset
																			</span>
																		</p>
																	</v-col>
																</v-row>
																<v-row class="px-4">
																	<v-col md="3" class="my-auto py-0">
																		<label for="sale-price" class="btx-label mt-2">Sales Price</label>
																	</v-col>
																	<v-col md="9" class="py-0">
																		<PriceInput
																			hide-details
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			id="sale-price"
																			placeholder="Sale Price"
																			v-model="asset.sold_price"
																		></PriceInput>
																	</v-col>
																	<v-col md="3" class="my-auto py-0">
																		<label for="contact" class="btx-label mt-2">Is Serviceable</label>
																	</v-col>
																	<v-col md="9">
																		<v-checkbox
																			dense
																			v-model="asset.is_sold_service"
																			color="blue"
																			hide-details
																			class="mt-0 mb-0"
																		></v-checkbox>
																	</v-col>
																	<v-col md="12" class="min-height-40"> </v-col>
																</v-row>
															</template>
															<template v-if="asset.is_rental && asset_setting.assetrental">
																<v-row>
																	<v-col md="12" class="py-0">
																		<p class="middle-header-background my-4 mt-7 px-4">
																			Rental Information<br />
																			<span>
																				<v-icon small>mdi-progress-question</v-icon> Specify the rental details for your
																				asset
																			</span>
																		</p>
																	</v-col>
																</v-row>
																<v-row class="px-4">
																	<v-col md="3" class="my-auto py-0">
																		<label for="cost-price" class="btx-label mt-2 required">Min Period</label>
																	</v-col>
																	<v-col md="9" class="py-0">
																		<v-layout>
																			<v-flex md6>
																				<NumberInput
																					hide-details
																					:disabled="pageLoading"
																					:loading="pageLoading"
																					:id="`checkout-duration-${uniqFieldId}`"
																					placeholder="Min Period"
																					v-model="asset.min_period"
																				></NumberInput>
																			</v-flex>
																			<v-flex md6>
																				<SelectInput
																					hide-details
																					:items="durationList"
																					:disabled="pageLoading"
																					:loading="pageLoading"
																					placeholder="Duration"
																					v-model="asset.min_period_type"
																				></SelectInput>
																			</v-flex>
																		</v-layout>
																	</v-col>
																	<v-col md="3" class="my-auto py-0">
																		<label for="cost-price" class="btx-label mt-2 required">Max Period</label>
																	</v-col>
																	<v-col md="9" class="py-0">
																		<v-layout>
																			<v-flex md6>
																				<NumberInput
																					hide-details
																					:disabled="pageLoading"
																					:loading="pageLoading"
																					:id="`checkout-duration-${uniqFieldId}`"
																					placeholder="Max Period"
																					v-model="asset.max_period"
																				></NumberInput>
																			</v-flex>
																			<v-flex md6>
																				<SelectInput
																					hide-details
																					:items="durationList"
																					:disabled="pageLoading"
																					:readonly="true"
																					:loading="pageLoading"
																					placeholder="Duration"
																					v-model="asset.min_period_type"
																				></SelectInput>
																			</v-flex>
																		</v-layout>
																	</v-col>
																	<v-col md="3" class="my-auto py-0">
																		<label for="cost-price" class="btx-label mt-2 required"
																			>Delay Between Rentals</label
																		>
																	</v-col>
																	<v-col md="9" class="py-0">
																		<v-layout>
																			<v-flex md6>
																				<NumberInput
																					hide-details
																					:disabled="pageLoading"
																					:loading="pageLoading"
																					:id="`checkout-duration-${uniqFieldId}`"
																					placeholder="Delay Period"
																					v-model="asset.rental_delay_period"
																				></NumberInput>
																			</v-flex>
																			<v-flex md6>
																				<SelectInput
																					hide-details
																					:items="durationList"
																					:disabled="pageLoading"
																					:readonly="true"
																					:loading="pageLoading"
																					placeholder="Duration"
																					v-model="asset.rental_delay_period_type"
																				></SelectInput>
																			</v-flex>
																		</v-layout>
																	</v-col>
																	<v-col md="3" class="my-auto py-0">
																		<label for="contact" class="btx-label mt-2">Per Minute Pricing</label>
																	</v-col>
																	<v-col md="9">
																		<NumberInput
																			hide-details
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			:id="`checkout-duration-${uniqFieldId}`"
																			placeholder="Per Minute Rental Pricing"
																			v-model="asset.minute_rental_price"
																		></NumberInput>
																	</v-col>
																	<v-col md="3" class="my-auto py-0">
																		<label for="contact" class="btx-label mt-2">Per Hour Pricing</label>
																	</v-col>
																	<v-col md="9">
																		<NumberInput
																			hide-details
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			:id="`checkout-duration-${uniqFieldId}`"
																			placeholder="Per Hour Rental Pricing"
																			v-model="asset.hour_rental_price"
																		></NumberInput>
																	</v-col>
																	<v-col md="3" class="my-auto py-0">
																		<label for="contact" class="btx-label mt-2">Per Day Pricing</label>
																	</v-col>
																	<v-col md="9">
																		<NumberInput
																			hide-details
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			:id="`checkout-duration-${uniqFieldId}`"
																			placeholder="Per Day Rental Pricing"
																			v-model="asset.day_rental_price"
																		></NumberInput>
																	</v-col>
																	<v-col md="3" class="my-auto py-0">
																		<label for="contact" class="btx-label mt-2">Per Week Pricing</label>
																	</v-col>
																	<v-col md="9">
																		<NumberInput
																			hide-details
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			:id="`checkout-duration-${uniqFieldId}`"
																			placeholder="Per Week Rental Pricing"
																			v-model="asset.week_rental_price"
																		></NumberInput>
																	</v-col>
																	<v-col md="3" class="my-auto py-0">
																		<label for="contact" class="btx-label mt-2">Per Month Pricing</label>
																	</v-col>
																	<v-col md="9">
																		<NumberInput
																			hide-details
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			:id="`checkout-duration-${uniqFieldId}`"
																			placeholder="Per Month Rental Pricing"
																			v-model="asset.month_rental_price"
																		></NumberInput>
																	</v-col>
																	<v-col md="12" class="min-height-40"> </v-col>
																</v-row>
															</template>
															<v-row>
																<v-col md="12" class="py-0">
																	<p class="middle-header-background my-4 mt-7 px-4">
																		Purchased Information<br />
																		<span>
																			<v-icon small>mdi-progress-question</v-icon> Specify the purchase and location
																			details for your asset
																		</span>
																	</p>
																</v-col>
															</v-row>
															<v-row class="px-4">
																<v-col md="3" class="my-auto py-0">
																	<label for="cost-price" class="btx-label mt-2">Cost Price</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<PriceInput
																		hide-details
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="cost-price"
																		placeholder="Cost Price"
																		v-model="asset.cost_price"
																	></PriceInput>
																</v-col>
																<v-col md="3" class="my-auto py-0">
																	<label for="vendor" class="btx-label mt-2 required">Supplier</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<AutoCompleteInput
																		hide-details
																		:items="vendorList"
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="vendor"
																		placeholder="Supplier"
																		v-model="asset.vendor"
																		:rules="[vrules.required(asset.vendor, 'Vendor')]"
																		:class="{
																			required: !asset.vendor,
																		}"
																		append-outer-icon="mdi-cog"
																		v-on:click:append-outer="manageSupplierDialog = true"
																	>
																	</AutoCompleteInput>
																</v-col>
																<template v-if="false">
																	<v-col md="3" class="my-auto py-0">
																		<label for="vendor" class="btx-label mt-2">Staff</label>
																	</v-col>
																	<v-col md="9" class="py-0">
																		<AutoCompleteInput
																			hide-details
																			:items="memberList"
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			id="vendor"
																			placeholder="Staff"
																			v-model="asset.member"
																		></AutoCompleteInput>
																	</v-col>
																</template>
																<template v-if="false">
																	<v-col md="3" class="my-auto py-0">
																		<label for="location" class="btx-label mt-2 required">Location</label>
																	</v-col>
																	<v-col md="9" class="py-0">
																		<AutoCompleteInput
																			hide-details
																			:items="locationList"
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			id="location"
																			placeholder="Location"
																			v-model="asset.location"
																			:rules="[vrules.required(asset.name, 'Name')]"
																			:class="{
																				required: !asset.name,
																			}"
																		></AutoCompleteInput>
																	</v-col>
																</template>
																<v-col md="3" class="my-auto py-0">
																	<label for="purchased-on" class="btx-label mt-2">Purchased On</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<DatePicker
																		hide-details
																		clearable
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="purchased-on"
																		placeholder="Purchased On"
																		v-model="asset.purchased_on"
																	></DatePicker>
																</v-col>
																<v-col md="3" class="my-auto py-0">
																	<label for="attachment" class="btx-label mt-2">Attachment</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<template v-for="(data, index) in btx_attachments">
																		<v-row :key="`file-row-${index}`" :class="{ 'table-alternate-row': index % 2 }">
																			<v-col md="5" class="py-0">
																				<v-file-input
																					v-model="data.file"
																					placeholder="Click here to select files"
																					color="blue darken-4"
																					multiple
																					outlined
																					class="mt-3"
																					prepend-icon=""
																					prepend-inner-icon="mdi-attachment"
																					hide-details
																					v-on:change="limitAttachment($event, index)"
																				>
																				</v-file-input>
																			</v-col>
																			<v-col md="5" class="py-0">
																				<TextInput hide-details placeholder="File Name" v-model="data.name"></TextInput>
																			</v-col>
																			<v-col md="1" class="py-0">
																				<v-btn
																					:disabled="btx_attachments.length < 2"
																					v-on:click="removeFile(index)"
																					class="white--text mt-3"
																					depressed
																					color="red darken-4"
																					tile
																					style="margin-left: -10px"
																				>
																					<v-icon>mdi-delete</v-icon>
																				</v-btn>
																			</v-col>
																			<v-col md="1" class="py-0">
																				<v-btn
																					:disabled="btx_attachments.length > 2"
																					v-on:click="addMore()"
																					class="white--text mt-3"
																					depressed
																					color="blue darken-4"
																					tile
																					style="margin-left: -13px"
																				>
																					<v-icon>mdi-plus</v-icon>
																				</v-btn>
																			</v-col>
																		</v-row>
																	</template>
																</v-col>

																<v-col md="3" class="py-0">
																	<label for="remark" class="btx-label mt-2">Remark</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<TextAreaInput
																		hide-details
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="remark"
																		placeholder="Remark"
																		v-model="asset.remark"
																	></TextAreaInput>
																</v-col>
																<v-col md="12" class="min-height-40"> </v-col>
															</v-row>
														</perfect-scrollbar>
													</v-col>
													<v-col md="4">
														<v-row>
															<v-col md="12" class="my-6 py-0 text-center">
																<ImageUpload
																	can-change
																	v-model="asset.image"
																	:page-loading="pageLoading"
																></ImageUpload>
															</v-col>
														</v-row>
													</v-col>
												</v-row>
											</perfect-scrollbar>
										</v-stepper-content>
										<v-stepper-content class="pt-0" step="2" v-if="asset.has_checkOut && asset.is_allocate">
											<perfect-scrollbar
												:options="{ suppressScrollX: true }"
												class="scroll custom-box-top-inner-shadow"
												style="max-height: calc(100vh - 185px); position: relative"
											>
												<v-row>
													<v-col md="9">
														<perfect-scrollbar
															:options="{ suppressScrollX: true }"
															class="scroll custom-box-top-inner-shadow"
															style="max-height: calc(100vh - 160px); position: relative"
														>
															<v-row>
																<v-col md="12" class="py-0">
																	<p class="middle-header-background my-4 mt-7 px-4">
																		Issue Information<br />
																		<span>
																			<v-icon small>mdi-progress-question</v-icon> Specify the issue details for your
																			asset
																		</span>
																	</p>
																</v-col>
															</v-row>
															<v-row class="px-4">
																<v-col md="3" class="my-auto py-0">
																	<label for="cost-price" class="btx-label mt-2"> Duration</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<v-layout>
																		<v-flex md6>
																			<NumberInput
																				hide-details
																				:disabled="pageLoading"
																				:loading="pageLoading"
																				:id="`asset.checkout-duration-${uniqFieldId}`"
																				placeholder="Duration"
																				v-model="asset.checkout.duration"
																				v-on:keyup="updateDuration()"
																			></NumberInput>
																		</v-flex>
																		<v-flex md6>
																			<SelectInput
																				hide-details
																				:items="durationList"
																				:disabled="pageLoading"
																				:loading="pageLoading"
																				placeholder="Duration"
																				v-model="asset.checkout.duration_type"
																				v-on:change="updateDuration()"
																			></SelectInput>
																		</v-flex>
																	</v-layout>
																</v-col>
																<v-col md="3" class="py-0"> </v-col>
																<v-col md="9" class="py-0">
																	<v-checkbox
																		dense
																		hide-details
																		label="Issue indefinitely"
																		class="mt-3 mb-0 p-0"
																		:true-value="1"
																		:false-value="0"
																		v-model="asset.checkout.indefinitely"
																	></v-checkbox>
																</v-col>
																<v-col md="3" class="my-auto py-0">
																	<label for="vendor" class="btx-label mt-2 required">Return On</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<v-col md="9" class="py-0 pl-0">
																		<DateTimePicker
																			hide-details
																			:disabled="(asset.checkout.indefinitely ? true : false) || pageLoading"
																			:loading="pageLoading"
																			:id="`return-on-${uniqFieldId}`"
																			required
																			placeholder="Return On"
																			v-model="asset.checkout.return_on"
																		></DateTimePicker>
																	</v-col>
																</v-col>
																<v-col v-if="false" md="3" class="py-0"> </v-col>
																<v-col v-if="false" md="9" class="py-0">
																	<v-checkbox
																		dense
																		hide-details
																		label="Issue Loan"
																		class="mt-3 mb-0 p-0"
																		:true-value="1"
																		:false-value="0"
																		v-model="asset.checkout.has_loan"
																	></v-checkbox>
																</v-col>
																<v-col md="3" class="py-0">
																	<label :for="`return-on-${uniqFieldId}`" class="btx-label mt-3"
																		>Approval Required</label
																	>
																</v-col>
																<v-col md="9" class="py-0">
																	<RadioInput
																		row
																		hide-details
																		v-model="asset.checkout.aprrove_transfer"
																		:disabled="pageLoading"
																		:id="`service-performed-by-${uniqFieldId}`"
																		:items="approvalIteam"
																	></RadioInput>
																</v-col>

																<v-col md="3" class="py-0">
																	<label :for="`return-on-${uniqFieldId}`" class="btx-label mt-3">Issued To</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<RadioInput
																		row
																		hide-details
																		v-model="asset.checkout.checkout_by"
																		:disabled="pageLoading"
																		:id="`asset-checkout-performed-by-${uniqFieldId}`"
																		:items="performedItems"
																	></RadioInput>
																</v-col>
																<v-col md="3" class="my-auto py-0">
																	<label for="Issue To" class="btx-label mt-2"> </label>
																</v-col>
																<template v-if="asset.checkout.checkout_by == true">
																	<v-col md="9" class="py-0">
																		<AutoCompleteInputMember
																			hide-details
																			:items="memberList"
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			:id="`member-${uniqFieldId}`"
																			:rules="[vrules.required(asset.checkout.member, 'Issue To')]"
																			:class="{
																				required: !asset.checkout.member,
																			}"
																			placeholder="Staff"
																			v-model="asset.checkout.member"
																		></AutoCompleteInputMember>
																	</v-col>
																</template>

																<template v-else>
																	<v-col md="9" class="py-0">
																		<AutoCompleteInputMember
																			hide-details
																			:items="memberList"
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			:id="`member-${uniqFieldId}`"
																			:rules="[vrules.required(asset.checkout.member, 'Issue To')]"
																			:class="{
																				required: !asset.checkout.member,
																			}"
																			placeholder="Staff"
																			v-model="asset.checkout.member"
																		></AutoCompleteInputMember>
																	</v-col>
																</template>

																<v-col md="3" class="my-auto py-0">
																	<label for="purchased-on" class="btx-label mt-2">Comments</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<TextAreaInput
																		hide-details
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		:id="`comment-${uniqFieldId}`"
																		placeholder="Comments"
																		v-model="asset.checkout.comment"
																	>
																	</TextAreaInput>
																</v-col>
																<v-col md="3" class="my-auto py-0">
																	<label for="attachment" class="btx-label mt-2">Attachment</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<template v-for="(data, index) in asset.checkout.ca_attachments">
																		<v-row :key="`file-row-${index}`" :class="{ 'table-alternate-row': index % 2 }">
																			<v-col md="5" class="py-0">
																				<v-file-input
																					v-model="data.file"
																					placeholder="Click here to select files"
																					color="blue darken-4"
																					multiple
																					outlined
																					class="mt-3"
																					prepend-icon=""
																					prepend-inner-icon="mdi-attachment"
																					hide-details
																					v-on:change="limitAttachmentCheckout($event, index)"
																				>
																				</v-file-input>
																			</v-col>
																			<v-col md="5" class="py-0">
																				<TextInput hide-details placeholder="File Name" v-model="data.name"></TextInput>
																			</v-col>
																			<v-col md="1" class="py-0">
																				<v-btn
																					:disabled="asset.checkout.ca_attachments.length < 2"
																					v-on:click="removeFileCheckout(index)"
																					class="white--text mt-3"
																					depressed
																					color="red darken-4"
																					tile
																					style="margin-left: -10px"
																				>
																					<v-icon>mdi-delete</v-icon>
																				</v-btn>
																			</v-col>
																			<v-col md="1" class="py-0">
																				<v-btn
																					:disabled="asset.checkout.ca_attachments.length > 2"
																					v-on:click="addMoreCheckout()"
																					class="white--text mt-3"
																					depressed
																					color="blue darken-4"
																					tile
																					style="margin-left: -13px"
																				>
																					<v-icon>mdi-plus</v-icon>
																				</v-btn>
																			</v-col>
																		</v-row>
																	</template>
																</v-col>

																<v-col md="3" class="py-0">
																	<label for="Reason" class="btx-label mt-2">Reason</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<TextAreaInput
																		hide-details
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		:id="`reason-${uniqFieldId}`"
																		placeholder="Reason"
																		v-model="asset.checkout.reason"
																	>
																	</TextAreaInput>
																</v-col>
																<template>
																	<v-col md="3" class="py-0"> </v-col>
																	<v-col md="9" class="py-0">
																		<v-checkbox
																			dense
																			hide-details
																			label="Ignore conflicting reservations"
																			:true-value="1"
																			:false-value="0"
																			class="mt-3 mb-0 p-0"
																			v-model="asset.checkout.ignore_conflict"
																		>
																		</v-checkbox>
																	</v-col>
																	<v-col md="3" class="py-0"> </v-col>
																	<v-col md="9" class="py-0">
																		<v-checkbox
																			dense
																			hide-details
																			label="Fulfill reservation on this Asset for the selected user, if any"
																			class="mt-3 mb-0 p-0"
																			:true-value="1"
																			:false-value="0"
																			v-model="asset.checkout.ful_fill"
																		>
																		</v-checkbox>
																	</v-col>
																	<v-col md="3" class="py-0"> </v-col>
																	<!-- <v-col md="9" class="py-0">
															<v-checkbox dense hide-details label="Show Reservations" class="mt-3 mb-0 p-0"
																v-model="showReservation" v-on:change="getReservations()"></v-checkbox>
														</v-col> -->
																</template>

																<v-col md="12" class="min-height-40"> </v-col>
															</v-row>
														</perfect-scrollbar>
													</v-col>
												</v-row>
											</perfect-scrollbar>
										</v-stepper-content>
										<v-stepper-content class="pt-0" step="3" v-if="asset.check_warranty">
											<perfect-scrollbar
												:options="{ suppressScrollX: true }"
												class="scroll custom-box-top-inner-shadow"
												style="max-height: calc(100vh - 185px); position: relative"
											>
												<v-row>
													<v-col md="9">
														<perfect-scrollbar
															:options="{ suppressScrollX: true }"
															class="scroll custom-box-top-inner-shadow"
															style="max-height: calc(100vh - 160px); position: relative"
														>
															<v-row>
																<v-col md="12" class="py-0">
																	<p class="middle-header-background my-4 mt-7 px-4">
																		Warranty Information<br />
																		<span>
																			<v-icon small>mdi-progress-question</v-icon> Specify the warranty details for
																			your asset
																		</span>
																	</p>
																</v-col>
															</v-row>
															<v-row class="px-4">
																<v-col md="3" class="my-auto py-0">
																	<label :for="`service-from-${uniqFieldId}`" class="btx-label mt-3 required"
																		>Warranty Period</label
																	>
																</v-col>
																<v-col md="9" class="py-0">
																	<v-layout>
																		<v-flex md6>
																			<NumberInput
																				hide-details
																				:disabled="pageLoading"
																				:loading="pageLoading"
																				:id="`checkout-duration-${uniqFieldId}`"
																				placeholder="Duration"
																				v-model="asset.warranty_duration"
																				v-on:keyup="(e) => updateWarrantyDuration(e, 'warranty_duration')"
																			></NumberInput>
																		</v-flex>
																		<v-flex md6>
																			<SelectInput
																				hide-details
																				:items="warrantyDurationList"
																				:disabled="pageLoading"
																				:loading="pageLoading"
																				placeholder="Duration"
																				v-model="asset.warranty_duration_type"
																				v-on:change="(e) => updateWarrantyDuration(e, 'warranty_duration_type')"
																			></SelectInput>
																		</v-flex>
																	</v-layout>
																</v-col>
																<v-col md="3" class="my-auto py-0">
																	<label for="cost-price" class="btx-label mt-2 required">Start Date</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<DatePicker
																		hide-details
																		clearable
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="warranty-start-date"
																		placeholder="Start Date"
																		v-model="asset.warranty_start_date"
																		v-on:change="(e) => updateWarrantyDuration(e, 'start_date')"
																		:rules="[vrules.required(asset.warranty_start_date, 'Warranty Start Date')]"
																		:class="{
																			required: !asset.warranty_start_date,
																		}"
																	></DatePicker>
																</v-col>
																<v-col md="3" class="my-auto py-0">
																	<label for="vendor" class="btx-label mt-2 required">End Date</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<DatePicker
																		hide-details
																		clearable
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="warranty-end-date"
																		placeholder="End Date"
																		:min-date="asset.warranty_start_date"
																		v-model="asset.warranty_end_date"
																		:rules="[vrules.required(asset.warranty_end_date, 'Warranty End Date')]"
																		:class="{
																			required: !asset.warranty_end_date,
																		}"
																		v-on:change="checkWarrantyDiff"
																	></DatePicker>
																</v-col>
																<v-col md="3" class="my-auto py-0">
																	<label for="reminder-type" class="btx-label mt-2 required">Reminder Type</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<AutoCompleteInput
																		dense
																		id="reminder-type"
																		hide-details
																		v-model="reminder.type"
																		placeholder="Select Reminder Type"
																		:items="reminder_type_items"
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		item-text="text"
																		item-value="value"
																		v-on:keyup="get_reminder_values()"
																	></AutoCompleteInput>
																</v-col>
																<v-col md="3" class="my-auto py-0">
																	<label for="reminder-start-date" class="btx-label mt-2 required"
																		><template v-if="reminder.type != 1">Start </template>Date</label
																	>
																</v-col>
																<v-col md="9" class="py-0">
																	<DatePicker
																		dense
																		id="reminder-start-date"
																		hide-details
																		v-model="reminder.start_date"
																		:placeholder="reminder.type != 1 ? 'Select Start Date...' : 'Select Date...'"
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		v-on:keyup="get_reminder_values()"
																		:min-date="formatDateRaw()"
																		:rules="[
																			vrules.required(reminder.start_date, reminder.type != 1 ? 'Start Date' : 'Date'),
																		]"
																	></DatePicker>
																</v-col>
																<template v-if="reminder.type != 1">
																	<v-col md="3" class="my-auto py-0">
																		<label for="reminder-frequency" class="btx-label mt-2 required">Frequency</label>
																	</v-col>
																	<v-col md="9" class="py-0">
																		<TextInput
																			dense
																			id="reminder-frequency"
																			hide-details
																			type="number"
																			v-model="reminder.frequency"
																			placeholder="Enter Frequency..."
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			v-on:keyup="get_reminder_values()"
																			:rules="[vrules.required(reminder.frequency, 'Frequency')]"
																			class="mt-0"
																		></TextInput>
																	</v-col>
																	<v-col md="3" class="my-auto py-0">
																		<label for="reminder-end-date" class="btx-label mt-2 required">End Date</label>
																	</v-col>
																	<v-col md="9" class="py-0">
																		<DatePicker
																			dense
																			:rules="[vrules.required(reminder_end_date, 'End Date')]"
																			id="reminder-end-date"
																			hide-details
																			v-model="reminder_end_date"
																			placeholder="Select End Date..."
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			readonly
																		></DatePicker>
																	</v-col>
																</template>
																<v-col md="3" class="my-auto py-0">
																	<label for="warranty-cost" class="btx-label mt-2">Cost</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<PriceInput
																		hide-details
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="warranty-cost"
																		placeholder="Warranty Cost"
																		v-model="asset.warranty_cost"
																	></PriceInput>
																</v-col>
																<v-col md="3" class="my-auto py-0">
																	<label for="attachment" class="btx-label mt-2">Attachment</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<template v-for="(data, index) in wtx_attachments">
																		<v-row :key="`file-row-${index}`" :class="{ 'table-alternate-row': index % 2 }">
																			<v-col md="5" class="py-0">
																				<v-file-input
																					v-model="data.file"
																					placeholder="Click here to select files"
																					color="blue darken-4"
																					multiple
																					outlined
																					class="mt-3"
																					prepend-icon=""
																					prepend-inner-icon="mdi-attachment"
																					hide-details
																					v-on:change="limitAttachmentWarranty($event, index)"
																				>
																				</v-file-input>
																			</v-col>
																			<v-col md="5" class="py-0">
																				<TextInput hide-details placeholder="File Name" v-model="data.name"></TextInput>
																			</v-col>
																			<v-col md="1" class="py-0">
																				<v-btn
																					:disabled="wtx_attachments.length < 2"
																					v-on:click="removeFileWarranty(index)"
																					class="white--text mt-3"
																					depressed
																					color="red darken-4"
																					tile
																					style="margin-left: -10px"
																				>
																					<v-icon>mdi-delete</v-icon>
																				</v-btn>
																			</v-col>
																			<v-col md="1" class="py-0">
																				<v-btn
																					:disabled="wtx_attachments.length > 2"
																					v-on:click="addMoreWarranty()"
																					class="white--text mt-3"
																					depressed
																					color="blue darken-4"
																					tile
																					style="margin-left: -13px"
																				>
																					<v-icon>mdi-plus</v-icon>
																				</v-btn>
																			</v-col>
																		</v-row>
																	</template>
																</v-col>
																<v-col md="3" class="py-0">
																	<label for="remark" class="btx-label mt-2">Remark</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<TextAreaInput
																		hide-details
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="remark"
																		placeholder="Remark"
																		v-model="asset.warranty_remark"
																	></TextAreaInput>
																</v-col>
																<v-col md="12" class="min-height-40"> </v-col>
															</v-row>
														</perfect-scrollbar>
													</v-col>
												</v-row>
											</perfect-scrollbar>
										</v-stepper-content>
										<v-stepper-content class="pt-0" step="4">
											<perfect-scrollbar
												:options="{ suppressScrollX: true }"
												class="scroll custom-box-top-inner-shadow"
												style="max-height: calc(100vh - 185px); position: relative"
											>
												<v-row>
													<v-col md="9">
														<perfect-scrollbar
															:options="{ suppressScrollX: true }"
															class="scroll custom-box-top-inner-shadow"
															style="max-height: calc(100vh - 160px); position: relative"
														>
															<v-row>
																<v-col md="12" class="py-0">
																	<p class="middle-header-background my-4 mt-7 px-4">
																		Maintenance Information<br />
																		<span>
																			<v-icon small>mdi-progress-question</v-icon> Specify the maintenance details for
																			your asset
																		</span>
																	</p>
																</v-col>
															</v-row>
															<v-row class="px-4">
																<v-col md="3" class="my-auto py-0">
																	<label for="cost-price" class="btx-label mt-2">Start Date</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<DatePicker
																		hide-details
																		clearable
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="maintenance-start-date"
																		placeholder="Start Date"
																		v-model="asset.maintenance_start_date"
																	></DatePicker>
																</v-col>
																<v-col md="3" class="my-auto py-0">
																	<label for="vendor" class="btx-label mt-2">End Date</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<DatePicker
																		hide-details
																		clearable
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="maintenance-end-date"
																		placeholder="End Date"
																		:min-date="asset.maintenance_start_date"
																		v-model="asset.maintenance_end_date"
																	></DatePicker>
																</v-col>
																<!-- <v-col md="3" class="my-auto py-0">
														<label for="location" class="btx-label mt-2">Reminder
															Date</label>
													</v-col>
													<v-col md="9" class="py-0">
														<DatePicker hide-details clearable :disabled="pageLoading"
															:loading="pageLoading" id="maintenance-reminder-date"
															placeholder="Reminder Date"
															:min-date="asset.maintenance_start_date"
															:max-date="asset.maintenance_end_date"
															v-model="asset.maintenance_reminder_date"></DatePicker>
													</v-col> -->
																<v-col md="3" class="my-auto py-0">
																	<label for="reminder-type" class="btx-label mt-2">Reminder Type</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<AutoCompleteInput
																		dense
																		id="reminder-type"
																		hide-details
																		v-model="manintaenaceReminder.type"
																		placeholder="Select Reminder Type"
																		:items="reminder_type_items"
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		item-text="text"
																		item-value="value"
																		v-on:keyup="get_manintaenaceReminder_values()"
																	>
																	</AutoCompleteInput>
																</v-col>
																<v-col md="3" class="my-auto py-0">
																	<label for="reminder-start-date" class="btx-label mt-2"
																		><template v-if="manintaenaceReminder.type != 1">Start </template>Date</label
																	>
																</v-col>
																<v-col md="9" class="py-0">
																	<DatePicker
																		dense
																		id="reminder-start-date"
																		hide-details
																		v-model="manintaenaceReminder.start_date"
																		:placeholder="
																			manintaenaceReminder.type != 1 ? 'Select Start Date...' : 'Select Date...'
																		"
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		v-on:keyup="get_manintaenaceReminder_values()"
																		:min-date="formatDateRaw()"
																	></DatePicker>
																</v-col>
																<template v-if="manintaenaceReminder.type != 1">
																	<v-col md="3" class="my-auto py-0">
																		<label for="manintaenaceReminder-frequency" class="btx-label mt-2"
																			>Frequency</label
																		>
																	</v-col>
																	<v-col md="9" class="py-0">
																		<TextInput
																			dense
																			id="manintaenaceReminder-frequency"
																			hide-details
																			type="number"
																			v-model="manintaenaceReminder.frequency"
																			placeholder="Enter Frequency..."
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			v-on:keyup="get_manintaenaceReminder_values()"
																			class="mt-0"
																		></TextInput>
																	</v-col>
																	<v-col md="3" class="my-auto py-0">
																		<label for="manintaenaceReminder-end-date" class="btx-label mt-2">End Date</label>
																	</v-col>
																	<v-col md="9" class="py-0">
																		<DatePicker
																			dense
																			id="reminder-end-date"
																			hide-details
																			v-model="manintaenaceReminder_end_date"
																			placeholder="Select End Date..."
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			readonly
																		></DatePicker>
																	</v-col>
																</template>
																<v-col md="3" class="my-auto py-0">
																	<label for="cost-price" class="btx-label mt-2">Maintenance Cost Price</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<PriceInput
																		hide-details
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="cost-price"
																		placeholder="Cost Price"
																		v-model="asset.maintenance_cost"
																	>
																	</PriceInput>
																</v-col>
																<v-col md="3" class="my-auto py-0">
																	<label :for="`service-performed-by-${uniqFieldId}`" class="btx-label mt-3"
																		>Maintenance by</label
																	>
																</v-col>
																<v-col md="9" class="py-0">
																	<RadioInput
																		row
																		hide-details
																		v-model="asset.manintaenaceAsset_by"
																		:disabled="pageLoading"
																		:id="`service-performed-by-${uniqFieldId}`"
																		:items="manintaenaceItems"
																	></RadioInput>
																</v-col>

																<template v-if="asset.manintaenaceAsset_by == 1">
																	<v-col md="3" class="my-auto py-0">
																		<label for="vendor" class="btx-label mt-2">Supplier</label>
																	</v-col>
																	<v-col md="9" class="py-0">
																		<AutoCompleteInput
																			hide-details
																			:items="vendorList"
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			:id="`vendor-${uniqFieldId}`"
																			placeholder="Supplier"
																			v-model="asset.maintenance_by"
																		></AutoCompleteInput>
																	</v-col>
																</template>
																<template v-else>
																	<v-col md="3" class="my-auto py-0">
																		<label :for="`member-${uniqFieldId}`" class="btx-label mt-3">Staff</label>
																	</v-col>
																	<v-col md="9" class="py-0">
																		<AutoCompleteInput
																			hide-details
																			:items="memberList"
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			:id="`member-${uniqFieldId}`"
																			placeholder="Staff"
																			v-model="asset.member"
																		>
																		</AutoCompleteInput>
																	</v-col>
																</template>
																<v-col md="12" class="min-height-40"> </v-col>
															</v-row>
														</perfect-scrollbar>
													</v-col>
												</v-row>
											</perfect-scrollbar>
										</v-stepper-content>
									</v-form>
								</v-stepper-items>
							</v-stepper>
						</v-col>
					</v-row>
				</template>
				<template v-else>
					<div class="mx-20 my-40 text-center">
						<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
					</div>
				</template>
			</template>
			<template v-slot:action>
				<v-row>
					<v-col md="12" class="text-right">
						<v-btn
							v-if="stepper > 1"
							:loading="pageLoading"
							class="white--text mr-2"
							depressed
							color="blue darken-4"
							tile
							:disabled="pageLoading"
							v-on:click="goBackStepperAsset()"
						>
							Back
						</v-btn>
						<v-btn
							:loading="pageLoading"
							class="white--text mr-2"
							depressed
							color="blue darken-4"
							tile
							v-on:click="updateOrCreate()"
						>
							Save <template v-if="stepper < 4">&amp; Next</template>
						</v-btn>
						<v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn>
					</v-col>
				</v-row>
			</template>
		</CreateDialog>
		<template v-if="manageCategoryDialog">
			<ManageCategory
				:dialog="manageCategoryDialog"
				:category="categoryList"
				v-on:close-dialog="manageCategoryDialog = false"
				v-on:success="getCategories"
			></ManageCategory>
		</template>
		<ManageSupplier
			:dialog="manageSupplierDialog"
			:category="categoryList"
			v-on:close-dialog="manageSupplierDialog = false"
			v-on:success="getCategories"
		></ManageSupplier>
	</v-sheet>
</template>
<script>
import { mapGetters } from "vuex";
import CreateDialog from "@/view/components/CreateDialog";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { CreateAsset, UpdateAsset, GetAsset } from "@/core/lib/asset.lib";
import { UploadFiles } from "@/core/lib/upload.lib";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TextInput from "@/view/components/TextInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import PhoneInput from "@/view/components/Phone";
import PriceInput from "@/view/components/PriceInput";
import DatePicker from "@/view/components/DatePicker";
import ImageUpload from "@/view/components/ImageUpload";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import { filter, toString, toSafeInteger } from "lodash";
import ManageCategory from "@/view/components/Manage-Category.vue";
import ManageSupplier from "@/view/components/Manage-Supplier.vue";
import ObjectPath from "object-path";
import NumberInput from "@/view/components/NumberInput";
import DateTimePicker from "@/view/components/DateTimePicker";
import SelectInput from "@/view/components/SelectInput";
import MomentJS from "moment-timezone";
import AutoCompleteInputMember from "@/view/components/AutoCompleteInputMember";
import ApiService from "@/core/services/api.service";
import RadioInput from "@/view/components/RadioInput";
export default {
	name: "inventory-create",
	title: "Create Perishables",
	data() {
		return {
			createDialog: true,
			currentDate: MomentJS().format("YYYY-MM-DD"),
			asset_setting: {},
			uuid: null,
			barcodeLoading: false,
			stepper: 1,
			duplicateUUID: null,
			barcode: null,
			manageCategoryDialog: false,
			formValid: true,
			pageLoading: false,
			attachment: [],
			categoryList: [],
			subGroupList: [],
			allSubGroupList: [],
			vendorList: [],
			memberList: [],
			locationList: [],
			manageSupplierDialog: false,
			reminder_type_items: [],
			reminder_end_date: null,
			manintaenaceReminder_end_date: null,
			reminder_total: null,

			performedItems: [
				{
					label: "Department",
					value: 3,
				},
				{
					label: "Staff",
					value: 2,
				},
			],
			manintaenaceItems: [
				{
					label: "Supplier",
					value: 1,
				},
				{
					label: "Staff",
					value: 2,
				},
			],
			approvalIteam: [
				{
					label: "Yes",
					value: 1,
				},
				{
					label: "No",
					value: 0,
				},
			],
			faultIteam: [
				{
					label: "Yes",
					value: 1,
				},
				{
					label: "No",
					value: 0,
				},
			],
			reminder: {
				type: 1,
				start_date: null,
				frequency: null,
				end_date: null,
				total: null,
			},
			manintaenaceReminder: {
				type: 1,
				start_date: null,
				frequency: null,
				end_date: null,
				total: null,
			},
			asset: {
				id: 0,
				name: null,
				model: null,
				pico_barcode: null,
				password: null,
				member: null,
				group: null,
				sub_group: null,
				id_number: null,
				description: null,
				remark: null,
				contact: null,
				cost_price: null,
				vendor: null,
				location: null,
				image: null,
				purchased_on: null,
				warranty_start_date: null,
				warranty_end_date: null,
				check_warranty: false,
				is_fault: 0,
				warranty_reminder_date: null,
				manintaenaceAsset_by: 1,
				maintenance_start_date: null,
				maintenance_end_date: null,
				maintenance_reminder_date: null,
				maintenance_by: null,
				maintenance_cost: null,
				has_checkOut: true,
				is_allocate: false,
				warranty_remark: null,
				customerList: [],
				tags: [],

				checkout: {
					duration: 1,
					duration_type: "week",
					return_on: null,
					indefinitely: 0,
					has_loan: 0,
					checkout_by: 0,
					signature: null,
					aprrove_transfer: 0,
					reason: null,
					ca_attachments: [
						{
							file: null,
							url: null,
							name: null,
						},
					],
					member: 0,
					quantity: 0,
					location: 0,
					comment: null,
					ignore_conflict: 0,
					ful_fill: 1,
					is_rent: 1,
					reservation_uuid: null,
				},
				warranty_duration: 0,
				warranty_duration_type: "day",
			},
			btx_attachments: [
				{
					file: null,
					url: null,
					name: null,
				},
			],
			wtx_attachments: [
				{
					file: null,
					url: null,
					name: null,
				},
			],
			pricingTypeList: [
				{
					text: "One Day",
					value: "day",
				},
				{
					text: "One Week",
					value: "week",
				},
				{
					text: "One Month",
					value: "month",
				},
			],
			search: null,
			items: [],
		};
	},
	components: {
		CreateDialog,
		TextInput,
		PhoneInput,
		PriceInput,
		ImageUpload,
		DatePicker,
		TextAreaInput,
		ManageCategory,
		TooltipQuestion,
		AutoCompleteInput,
		ManageSupplier,
		NumberInput,
		DateTimePicker,
		SelectInput,
		AutoCompleteInputMember,
		RadioInput,
	},
	methods: {
		checkWarrantyDiff(endDate) {
			if (this.asset.warranty_start_date && endDate) {
				let startDate = MomentJS(this.asset.warranty_start_date);
				let endData = MomentJS(endDate);
				let diffData = endData.diff(startDate, "days");
				this.asset.warranty_duration_type = "day";
				this.asset.warranty_duration = Number(diffData);
			}
		},
		pageTitle() {
			if (this.uuid) {
				return this.asset.name;
			}
			return "Create new Asset";
		},
		addMore() {
			this.btx_attachments.push({
				file: null,
				url: null,
				name: null,
			});
		},
		removeFile(index) {
			this.btx_attachments.splice(index, 1);
			if (this.btx_attachments.length < 1) {
				this.addMore();
			}
		},
		limitAttachment(output, index) {
			if (this.pageLoading) {
				return false;
			}

			const attachment = output.slice(0, 3);

			if (attachment.length <= 0) {
				return false;
			}

			const request = new FormData();

			for (let i = 0; i < attachment.length; i++) {
				request.append(`files[${i}]`, attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.btx_attachments[index].url = ObjectPath.get(response, "0.path", null);
					this.btx_attachments[index].name = ObjectPath.get(response, "0.name", null);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		addMoreWarranty() {
			this.wtx_attachments.push({
				file: null,
				url: null,
				name: null,
			});
		},
		removeFileWarranty(index) {
			this.wtx_attachments.splice(index, 1);
			if (this.wtx_attachments.length < 1) {
				this.addMoreWarranty();
			}
		},
		limitAttachmentWarranty(output, index) {
			if (this.pageLoading) {
				return false;
			}

			const attachment = output.slice(0, 3);

			if (attachment.length <= 0) {
				return false;
			}

			const request = new FormData();

			for (let i = 0; i < attachment.length; i++) {
				request.append(`files[${i}]`, attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.wtx_attachments[index].url = ObjectPath.get(response, "0.path", null);
					this.wtx_attachments[index].name = ObjectPath.get(response, "0.name", null);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		async updateOrCreate() {
			const _this = this;

			if (
				(_this.asset.has_checkOut == false || _this.asset.is_allocate == false) &&
				this.asset.check_warranty == false &&
				_this.stepper == 1
			) {
				_this.stepper = 4;
				return false;
			} else if (
				(_this.asset.has_checkOut == false || _this.asset.is_allocate == false) &&
				_this.stepper == 1
			) {
				_this.stepper = 3;
				return false;
			}
			if (_this.asset.check_warranty == false && _this.stepper == 2) {
				_this.stepper = 4;
				return false;
			}
			if (_this.stepper < 4) {
				_this.stepper++;
				return false;
			}
			const formErrors = _this.validateForm(_this.$refs.assetForm);

			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.assetForm.validate()) {
				return false;
			}

			const formData = {
				name: _this.asset.name,
				model: _this.asset.model,
				contact: _this.asset.contact,
				id_number: _this.asset.id_number,
				description: _this.asset.description,
				attachment: _this.btx_attachments,
				attachment_warranty: this.wtx_attachments,
				asset_checkout: this.asset.checkout,
				group: _this.asset.group,
				sub_group: _this.asset.sub_group,
				cost_price: _this.asset.cost_price,
				remark: _this.asset.remark,
				supplier: _this.asset.vendor,
				image: _this.asset.image,
				location: _this.asset.location,
				purchased_on: _this.asset.purchased_on,
				member: _this.asset.member,
				pico_barcode: _this.asset.pico_barcode,
				password: _this.asset.password,
				warranty_start_date: _this.asset.warranty_start_date,
				warranty_end_date: _this.asset.warranty_end_date,
				warranty_reminder_date: _this.reminder.start_date,
				warranty_reminder_endDate: _this.reminder_end_date,
				warranty_remark: _this.asset.warranty_remark,
				warranty_cost: _this.asset.warranty_cost,
				warranty_duration: _this.asset.warranty_duration,
				warranty_duration_type: _this.asset.warranty_duration_type,
				maintenance_start_date: _this.asset.maintenance_start_date,
				maintenance_end_date: _this.asset.maintenance_end_date,
				maintenance_reminder_date: _this.manintaenaceReminder.start_date,
				maintenance_reminder_endDate: _this.manintaenaceReminder_end_date,

				maintenance_reminder_frequency: _this.manintaenaceReminder.frequency,
				maintenance_reminder_type: _this.manintaenaceReminder.type,
				warranty_reminder_frequency: _this.reminder.frequency,
				warranty_reminder_type: _this.reminder.type,

				maintenance_by: _this.asset.maintenance_by,
				maintenance_cost: _this.asset.maintenance_cost,
				has_warranty: _this.asset.check_warranty,
				is_fault: _this.asset.is_fault,

				end_life: _this.asset.end_life,
				is_return: _this.asset.is_return,
				is_allocate: _this.asset.is_allocate,
				is_sold: _this.asset.is_sold,
				is_rental: _this.asset.is_rental,
				is_sold_service: _this.asset.is_sold_service,
				sold_price: _this.asset.sold_price,
				min_period: _this.asset.min_period,
				min_period_type: _this.asset.min_period_type,
				max_period: _this.asset.max_period,
				max_period_type: _this.asset.max_period_type,
				minute_rental_price: _this.asset.minute_rental_price,
				hour_rental_price: _this.asset.hour_rental_price,
				day_rental_price: _this.asset.day_rental_price,
				week_rental_price: _this.asset.week_rental_price,
				month_rental_price: _this.asset.month_rental_price,
				tags: _this.asset.tags,
			};

			try {
				_this.pageLoading = true;

				if (_this.uuid) {
					const { uuid } = await UpdateAsset(_this.uuid, formData);

					_this.$router.replace({
						name: "asset-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Asset has been updated." },
					]);
				} else {
					const { uuid } = await CreateAsset(formData);
					_this.getCartCount();
					_this.$router.replace({
						name: "asset-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Asset has been created." },
					]);
				}
			} catch (error) {
				console.log(error);
				//_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		getCartCount() {
			ApiService.setHeader();
			ApiService.get("cart/count").then(() => {});
		},
		getTags() {
			const _this = this;
			ApiService.setHeader();
			ApiService.get("all-tags").then((res) => {
				_this.items = res.data;
			});
		},
		addMoreCheckout() {
			this.asset.checkout.ca_attachments.push({
				file: null,
				url: null,
				name: null,
			});
		},
		removeFileCheckout(index) {
			this.asset.checkout.ca_attachments.splice(index, 1);
			if (this.asset.checkout.ca_attachments.length < 1) {
				this.addMore();
			}
		},
		limitAttachmentCheckout(output, index) {
			if (this.pageLoading) {
				return false;
			}

			const attachment = output.slice(0, 3);

			if (attachment.length <= 0) {
				return false;
			}

			const request = new FormData();

			for (let i = 0; i < attachment.length; i++) {
				request.append(`files[${i}]`, attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.asset.checkout.ca_attachments[index].url = ObjectPath.get(response, "0.path", null);
					this.asset.checkout.ca_attachments[index].name = ObjectPath.get(response, "0.name", null);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		get_manintaenaceReminder_values() {
			let reminderParseDate = MomentJS(this.manintaenaceReminder.start_date);
			/* let test = MomentJS(manintaenaceReminderParseDate).add(6, 'M');
			console.log(test.format('YYYY-MM-DD')); */

			for (var i = 0; i < this.manintaenaceReminder.frequency; i++) {
				if (this.manintaenaceReminder.type == 6) {
					/* Half Yearly */
					reminderParseDate = MomentJS(reminderParseDate).add(6, "months");
				}

				if (this.manintaenaceReminder.type == 5) {
					/* Quarterly */
					reminderParseDate = MomentJS(reminderParseDate).add(3, "months");
				}

				if (this.manintaenaceReminder.type == 4) {
					/* Monthly */
					reminderParseDate = MomentJS(reminderParseDate).add(1, "months");
				}

				if (this.manintaenaceReminder.type == 3) {
					/* Weekly */
					reminderParseDate = MomentJS(reminderParseDate).add(7, "days");
				}

				if (this.manintaenaceReminder.type == 2) {
					/* Daily */
					reminderParseDate = MomentJS(reminderParseDate).add(1, "days");
				}

				if (this.manintaenaceReminder.type == 1) {
					/* One Time */
					reminderParseDate = MomentJS(reminderParseDate).add(7, "days");
				}
			}

			return (this.manintaenaceReminder_end_date = reminderParseDate.format("YYYY-MM-DD"));
		},
		get_reminder_values() {
			let reminderParseDate = MomentJS(this.reminder.start_date);
			/* let test = MomentJS(reminderParseDate).add(6, 'M');
			console.log(test.format('YYYY-MM-DD')); */

			for (var i = 0; i < this.reminder.frequency; i++) {
				if (this.reminder.type == 6) {
					/* Half Yearly */
					reminderParseDate = MomentJS(reminderParseDate).add(6, "months");
				}

				if (this.reminder.type == 5) {
					/* Quarterly */
					reminderParseDate = MomentJS(reminderParseDate).add(3, "months");
				}

				if (this.reminder.type == 4) {
					/* Monthly */
					reminderParseDate = MomentJS(reminderParseDate).add(1, "months");
				}

				if (this.reminder.type == 3) {
					/* Weekly */
					reminderParseDate = MomentJS(reminderParseDate).add(7, "days");
				}

				if (this.reminder.type == 2) {
					/* Daily */
					reminderParseDate = MomentJS(reminderParseDate).add(1, "days");
				}

				if (this.reminder.type == 1) {
					/* One Time */
					reminderParseDate = MomentJS(reminderParseDate).add(7, "days");
				}
			}

			return (this.reminder_end_date = reminderParseDate.format("YYYY-MM-DD"));
		},
		goBackStepperAsset() {
			if (
				(this.asset.has_checkOut == false || this.asset.is_allocate == false) &&
				this.asset.check_warranty == false &&
				this.stepper == 4
			) {
				this.stepper = 1;
				return false;
			} else if (this.asset.check_warranty == false && this.stepper == 4) {
				this.stepper = 2;
				return false;
			} else if (
				this.asset.has_checkOut == false ||
				(this.asset.is_allocate == false && this.stepper == 3)
			) {
				this.stepper = 1;
				return false;
			}
			this.stepper--;
		},
		getSubGroup(param) {
			this.subGroupList = filter(this.allSubGroupList, { group: param });
		},
		getAsset() {
			GetAsset(this.uuid)
				.then((data) => {
					console.log(data);
					this.barcode = data.barcode;
					this.asset.name = data.name;
					this.asset.model = data.model;
					this.asset.group = data.group;
					this.asset.sub_group = data.sub_group;
					this.asset.id_number = data.id_number;
					this.asset.description = data.description;
					this.asset.contact = data.contact;
					this.asset.cost_price = data.cost_price;
					this.asset.vendor = data.supplier;
					this.asset.location = data.location;
					this.asset.image = data.image;
					this.asset.remark = data.remark;
					this.asset.purchased_on = data.purchased_on;
					this.asset.pico_barcode = data.pico_barcode;
					this.asset.password = data.password;
					this.asset.member = data.member;
					this.asset.end_life = data.end_life;
					this.asset.warranty_start_date = data.warranty_start_date;
					this.asset.warranty_end_date = data.warranty_end_date;
					this.asset.warranty_reminder_date = data.warranty_reminder_date;
					this.asset.warranty_cost = data.warranty_cost;
					this.asset.maintenance_start_date = data.maintenance_start_date;
					this.asset.maintenance_end_date = data.maintenance_end_date;
					this.asset.maintenance_reminder_date = data.maintenance_reminder_date;
					this.asset.maintenance_by = data.maintenance_by;
					this.asset.maintenance_cost = data.maintenance_cost;
					this.asset.is_fault = data.is_fault;
					this.manintaenaceReminder.frequency = data.maintenance_reminder_frequency;
					this.manintaenaceReminder.type = data.maintenance_reminder_type;
					this.reminder.frequency = data.warranty_reminder_frequency;
					this.reminder.type = data.warranty_reminder_type;
					this.reminder.start_date = data.warranty_reminder_date;
					this.reminder_end_date = data.warranty_reminder_endDate;
					this.manintaenaceReminder.start_date = data.maintenance_reminder_date;
					this.manintaenaceReminder_end_date = data.maintenance_reminder_endDate;

					this.$nextTick(() => {
						if (this.asset.group) {
							this.getSubGroup(this.asset.group);
						}
					});

					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Asset", disabled: true },
						{ text: "Update", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateDuration() {
			let days = 1;

			let { duration_type, duration } = this.checkout;

			switch (duration_type) {
				case "day":
					days = toSafeInteger(duration);
					break;
				case "week":
					days = 7 * toSafeInteger(duration);
					break;
				case "month":
					days = 30 * toSafeInteger(duration);
					break;
			}

			this.$nextTick(() => {
				const date = MomentJS().add(days, "days");
				this.checkout.return_on = this.formatDateTimeRaw(date);
			});
		},

		updateWarrantyDuration(e, type) {
			let warranty_start_date = e;
			if (type != "start_date") {
				warranty_start_date =
					this.asset && this.asset.warranty_start_date ? this.asset.warranty_start_date : null;
			}
			if (warranty_start_date) {
				let { warranty_duration_type, warranty_duration } = this.asset;
				let date = MomentJS(warranty_start_date, "YYYY-MM-DD");
				let duration = warranty_duration ? toSafeInteger(warranty_duration) : 0;
				switch (warranty_duration_type) {
					case "day":
						date = MomentJS(warranty_start_date, "YYYY-MM-DD").add(duration, "d");
						break;
					case "month":
						date = MomentJS(warranty_start_date, "YYYY-MM-DD").add(duration, "M");
						break;
					case "year":
						date = MomentJS(warranty_start_date, "YYYY-MM-DD").add(duration, "y");
						break;
				}
				/* this.$nextTick(() => {
					this.asset.warranty_end_date = date.format("YYYY-MM-DD");
				}); */
				this.asset.warranty_end_date = date.format("YYYY-MM-DD");
			}
		},

		getDuplicateAsset() {
			GetAsset(this.duplicateUUID)
				.then((data) => {
					this.barcode = data.barcode;
					this.asset.name = data.name;
					this.asset.model = data.model;
					this.asset.group = data.group;
					this.asset.sub_group = data.sub_group;
					this.asset.id_number = data.id_number;
					this.asset.description = data.description;
					this.asset.contact = data.contact;
					this.asset.cost_price = data.cost_price;
					this.asset.vendor = data.supplier;
					this.asset.location = data.location;
					this.asset.image = data.image;
					this.asset.remark = data.remark;
					this.asset.purchased_on = data.purchased_on;
					this.asset.pico_barcode = data.pico_barcode;
					this.asset.password = data.password;
					this.asset.member = data.member;
					this.asset.is_fault = data.is_fault;
					this.asset.warranty_start_date = data.warranty_start_date;
					this.asset.warranty_end_date = data.warranty_end_date;
					this.asset.warranty_reminder_date = data.warranty_reminder_date;
					this.asset.maintenance_start_date = data.maintenance_start_date;
					this.asset.maintenance_end_date = data.maintenance_end_date;
					this.asset.maintenance_reminder_date = data.maintenance_reminder_date;
					this.asset.maintenance_by = data.maintenance_by;
					this.asset.maintenance_cost = data.maintenance_cost;

					this.$nextTick(() => {
						if (this.asset.group) {
							this.getSubGroup(this.asset.group);
						}
					});

					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Asset", disabled: true },
						{ text: "Create", disabled: true },
					]);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getCategories(data) {
			this.categoryList = data;
		},
		filterTag(item, queryText, itemText) {
			if (item.header) return false;
			const hasValue = (val) => (val != null ? val : "");

			const text = hasValue(itemText);
			const query = hasValue(queryText);

			return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
		},
		initialize() {
			this.categoryList = this.localDB("groups", []);
			this.allSubGroupList = this.localDB("sub_groups", []);
			this.vendorList = this.localDB("suppliers", []);
			this.memberList = this.localDB("members", []);
			this.locationList = this.localDB("locations", []);
			//this.customerList = this.localDB("customers", []);
			this.reminder_type_items = this.localDB("reminder_type_items", []);
			this.asset_setting = this.localDB("asset_setting", {});
		},
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
	},
	mounted() {
		this.initialize();
		this.getTags();

		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Asset", disabled: true },
			{ text: "Create", disabled: true },
		]);

		const { name, params, query } = this.$route;

		this.duplicateUUID = toString(query.duplicate);

		if (query && query.supplier) {
			this.asset.vendor = query.supplier;
		}
		if (query && query.group) {
			this.asset.group = query.group;
		}

		if (name === "asset-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.pageLoading = true;
			this.getAsset();
		} else if (this.duplicateUUID) {
			this.pageLoading = true;
			this.getDuplicateAsset();
		}
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
	watch: {
		"asset.tags": {
			deep: true,
			handler(val, prev) {
				if (val.length === prev.length) return;

				this.asset.tags = val.map((v) => {
					if (typeof v === "string") {
						v = {
							tag: v,
						};
						this.items.push(v);
					}

					return v;
				});
			},
		},
	},
};
</script>
